<ng-template #cartmodal>
    <!-- <div class="modal-header">
        <h4 class="modal-title pull-left"><svg class="icon-check" aria-hidden="true" viewBox="0 0 32 32"
                focusable="false">
                <path d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32"></path>
                <path fill="#FFF"
                    d="M23.6 13.2l-7 9a2 2 0 0 1-3 .2l-5-5a2 2 0 1 1 2.8-2.8l3.4 3.4 5.6-7.2a2 2 0 1 1 3.2 2.4"></path>
            </svg> Toegevoegd aan winkelmand</h4>
        <button type="button" class="close pull-right"
            style="fill: #ff7100; color: #ff7100; opacity: 1; font-size: 22px; display: contents;" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
        </button>
    </div> -->
    <div class="modal-body">
        <h4 class="modal-title pull-left"><svg class="icon-check" aria-hidden="true" viewBox="0 0 32 32"
                focusable="false">
                <path d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32"></path>
                <path fill="#FFF"
                    d="M23.6 13.2l-7 9a2 2 0 0 1-3 .2l-5-5a2 2 0 1 1 2.8-2.8l3.4 3.4 5.6-7.2a2 2 0 1 1 3.2 2.4"></path>
            </svg> Toegevoegd aan winkelmand</h4>
        <br>


        <div class="container">
            <div class="row">

                <div class="col-3 col-sm-2"> <img src="{{this.productimg}}" style="width: 100%;"></div>
                <div class="col-9 col-sm-10 px-0"> <!--{{this.productcount}}x -->{{this.productname}} </div>

            </div>

            <ng-container *ngIf="this.combinatiecount > 0">
                <!-- <div class="row">&nbsp;</div> -->
                <div class="row" style="margin-top: 5px;">

                    <div class="col-3 col-sm-2"> <img src="{{this.combinatieimg}}" style="width: 100%;"></div>
                    <div class="col-9 col-sm-10 px-0"> <span class="orangebold">Combinatiekorting</span><br>
                        <!--{{this.combinatiecount}}x -->{{this.combinatiename}} </div>


                </div>
            </ng-container>


        </div>


        <ng-container *ngIf="this.productcategorie == 1 || this.productcategorie == 2">

            <hr>



            <h4>
                <img src="/assets/images/icons/round-plus.svg" alt="plus icon"
                    style="vertical-align: text-top; width: 16px;">

                Maak je vloer compleet met deze plinten
            </h4>
            <br>

            <form #form1="ngForm" (ngSubmit)="onFormSubmit(form1.value)">
                <div class="container">
                    <div class="row">




                        <div class="col-2 col-sm-2"> <img
                                src="https://storage.voordeelvloeren.nl/images/prod/plakplinten-zelfklevend_800x800.jpg"
                                style="width: 100%;"></div>
                        <div class="col-5 col-md-6 px-0"> Plakplint Universeel 240 cm <br>

                            <span style="padding: 3px 5px 0px 0px; fill: #0090e3; display: inline-block;"><img
                                    _ngcontent-serverapp-c120="" src="/assets/images/icons/rechts-blauw-dik.svg"
                                    width="10" height="15" style="vertical-align: text-top;"></span>
                            <a _ngcontent-serverapp-c120="" target="_blank"
                                href="/product/573/plakplint-universeel-240-cm">Bekijk product</a>
                        </div>
                        <div class="col-2 col-md-2">



                            <div style=" text-align: center;">
                                <div style="display: inline-grid; text-align: center;">

                                    <span style="float: left; padding-top: 3px;">

                                        <input class="form-control input-number__input form-control-sm" type="number"
                                            style="width: 45px; padding: 2px 7px 0px 7px !important; min-width: 45px !important;"
                                            [min]="1" [max]="999" onfocus="this.value=''" id="number1" name="number1"
                                            ngModel="1" required pattern="^-?[0-9]\d*">

                                        <!-- <div class="input-number__add" (mousedown)="addpak()"></div>
                                                    <div class="input-number__sub" (mousedown)="subpak()"></div> -->


                                    </span>
                                </div>
                            </div>



                        </div>

                        <div class="col-2 col-sm-1"><button type="submit"
                                class="btn btn-primary product-card__addtocart" tabindex="0"><svg width="20px"
                                    height="20px">
                                    <use xlink:href="assets/images/sprite.svg#cart-20"></use>
                                </svg></button></div>
                        <div *ngIf="this.addedtocart1" class="col-12"
                            style="padding-top: 5px; text-align: center; font-weight: bold;">Artikel toegevoegd aan de
                            winkelmand</div>
                    </div>

                </div>
            </form>
            <hr>
            <form #form2="ngForm" (ngSubmit)="onFormSubmit(form2.value)">
                <div class="container">
                    <div class="row">




                        <div class="col-2 col-sm-2"> <img
                                src="https://storage.voordeelvloeren.nl/images/prod//rechte-folieplint-70x14-universeel-vloer_800x800.jpg"
                                style="width: 100%;"></div>
                        <div class="col-5 col-md-6 px-0"> Rechte folieplint 70 x 14 mm Universeel <br>

                            <span style="padding: 3px 5px 0px 0px; fill: #0090e3; display: inline-block;"><img
                                    _ngcontent-serverapp-c120="" src="/assets/images/icons/rechts-blauw-dik.svg"
                                    width="10" height="15" style="vertical-align: text-top;"></span>
                            <a _ngcontent-serverapp-c120="" target="_blank"
                                href="/product/1666/rechte-folieplint-70-x-14-mm-universeel">Bekijk product</a>
                        </div>
                        <div class="col-2 col-md-2 ">



                            <div style=" text-align: center;">
                                <div style="display: inline-grid; text-align: center;">

                                    <span style="float: left; padding-top: 3px;">

                                        <input class="form-control input-number__input form-control-sm" type="number"
                                            style="width: 45px; padding: 2px 7px 0px 7px !important; min-width: 45px !important;"
                                            [min]="1" [max]="999" onfocus="this.value=''" id="number2" name="number2"
                                            ngModel="1" required pattern="^-?[0-9]\d*">

                                        <!-- <div class="input-number__add" (mousedown)="addpak()"></div>
                                                    <div class="input-number__sub" (mousedown)="subpak()"></div> -->


                                    </span>
                                </div>
                            </div>



                        </div>

                        <div class="col-2 col-sm-1"><button type="submit"
                                class="btn btn-primary product-card__addtocart" tabindex="0"><svg width="20px"
                                    height="20px">
                                    <use xlink:href="assets/images/sprite.svg#cart-20"></use>
                                </svg></button></div>

                        <div *ngIf="this.addedtocart2" class="col-12"
                            style="padding-top: 5px; text-align: center; font-weight: bold;">Artikel toegevoegd aan de
                            winkelmand</div>
                    </div>
                </div>
            </form>
        </ng-container>

    </div>


    <div class="modal-footer">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 mb-3 mb-md-0">
                    <button type="button" [routerLink]="root.cart()" class="btn btn-primary btn-lg" style="width: 100%;"
                        (click)="modalRef.hide()">Winkelmand</button>
                </div>
                <div class="col-12 col-md-6">
                    <button type="button" class="btn btn-secondary btn-lg" style="width: 100%;"
                        (click)="modalRef.hide()">Sluit venster</button>
                </div>

            </div>


        </div>
    </div>
</ng-template>
